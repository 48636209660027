/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styled from "styled-components";
import Logo from "./Logo";
import PoliticaCookiesPrivacidade from "../termos/politica_cookies_privacidade.pdf";
import LGPD from "../termos/lgpd.pdf";

const FooterWrapper = styled.footer`
  color: #fff;
  padding: 95px 80px;
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: 991px) {
    padding: 60px 20px;
  }
`;

const FooterBackground = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const FooterContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1206px;
  margin: 0 auto;

  @media (max-width: 539px) {
    flex-direction: column;
  }
`;

const FooterColumn = styled.div`
  margin-bottom: 20px;
`;

const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  display: block;
  margin-bottom: 10px;
`;

const FooterButtons = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 20px;
`;

const FooterButton = styled.a`
  display: inline-block;
  border-radius: 16px;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  color: #000;
`;

const WhatsAppButton = styled(FooterButton)`
  background-color: #25d366;
  height: 18px;
`;

const TevButton = styled(FooterButton)`
  background-color: #fdb913;
  height: 18px;
  cursor: pointer;
`;

const FooterDivider = styled.hr`
  border: none;
  height: 3px;
  background-color: rgba(217, 217, 217, 0.6);

  position: relative;
  align-self: center;
  width: 100%;
  max-width: 1206px;
`;

const FooterBottom = styled.div`
  position: relative;
  align-self: center;
  width: 100%;
  max-width: 1206px;
  margin-top: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FooterCopyright = styled.p`
  font-size: 14px;
`;

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterBackground
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/27ebea72c6ffb65a65bcfa99bd4f14d0a9719b7b608c188caf62e54413f2728c?placeholderIfAbsent=true&apiKey=bd0dc80f9a284b7b95d02b096da53a39"
      alt="Footer background"
    />

    <FooterContent>
      <FooterColumn>
        {/* <FooterLink href="#compliance">Compliance</FooterLink> */}
        <FooterLink href="https://forms.office.com/r/4kq02EmCzr">
          Requerimento de dados do titular
        </FooterLink>
        <FooterLink href={LGPD} target="_blank" rel="noopener noreferrer">
          LGPD
        </FooterLink>
      </FooterColumn>
      <FooterColumn>
        <FooterLink
          href={PoliticaCookiesPrivacidade}
          target="_blank"
          rel="noopener noreferrer"
        >
          Política de cookies e privacidade
        </FooterLink>
      </FooterColumn>
      <FooterColumn>
        <FooterLink href="mailto:contato@conectaonline.net.br">
          contato@conectaonline.net.br
        </FooterLink>
        <FooterLink href="tel:+5511912229926">(11) 91222-9926</FooterLink>
      </FooterColumn>
      <FooterButtons>
        <WhatsAppButton href="https://wa.me/5511912229926">
          WhatsApp
        </WhatsAppButton>
        <TevButton 
          onClick={() => window.open('https://tev.net.br', 'blank')}
        >
          TEV
        </TevButton>
      </FooterButtons>
    </FooterContent>

    <FooterDivider />

    <FooterBottom>
      <Logo />
      <FooterCopyright>
        Copyright © 2025 Conecta todos os direitos reservados - 2323791524-43
      </FooterCopyright>
    </FooterBottom>
  </FooterWrapper>
);

export default Footer;
